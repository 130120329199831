import { MSG_GENERIC_ERROR } from '../config/constants';

const xhrError = (err) => {
    if (!err) return null;

    let errMsg =
        err && err.data && err.data.message
            ? err.data.message
            : MSG_GENERIC_ERROR;
    let field;

    if (err.data && err.data.errors) {
        const errorMessages = Object.entries(err.data.errors);

        if (errorMessages && errorMessages.length) {
            if (errorMessages[0] && errorMessages[0][1]) {
                if (Array.isArray(errorMessages[0][1])) {
                    errMsg = errorMessages[0][1][0];
                } else {
                    errMsg = errorMessages[0][1];
                }
            }

            field = errorMessages[0][0];
        }
    } else if (err.data && err.data.error) {
        errMsg = err.data.error;
    }

    if (err.status && err.status === 404 && err.data.message) {
        errMsg = err.data.message;
    } else if (err.status && err.status === 401 && err.data.error) {
        errMsg = err.data.error;

        setTimeout(() => {
            window.location.reload();
        }, 1000); // 1 seconds
    }

    return { message: errMsg, field };
};

export default xhrError;

export const normalizeData = (resp) => {
    let response = {};

    if (!resp || !resp.data) return response;

    response = Object.assign(response, {
        data: resp.data.data ? resp.data.data : resp.data,
    });

    if (Array.isArray(response.data)) {
        response.data = response.data.map((data) => {
            if (data.attributes) {
                data = { ...data, ...data.attributes };

                // normalize nested attributes
                Object.keys(data.attributes).map((key) => {
                    if (
                        data.attributes[`${key}`] &&
                        data.attributes[`${key}`].data
                    ) {
                        data[`${key}`] = normalizeData(
                            data.attributes[`${key}`],
                        );

                        let childAttr = data.attributes[`${key}`].data;

                        childAttr = normalizeData(data.attributes[`${key}`]);

                        data[`${key}`] = childAttr.data;
                    }
                });

                delete data.attributes;
            }

            return data;
        });
    } else {
        if (response.data.attributes) {
            Object.assign(response, {
                data: { ...response.data, ...response.data.attributes },
            });

            // normalize nested attributes
            Object.keys(response.data.attributes).map((key) => {
                if (
                    response.data.attributes[`${key}`] &&
                    response.data.attributes[`${key}`].data
                ) {
                    let childAttr = response.data.attributes[`${key}`].data;

                    childAttr = normalizeData(
                        response.data.attributes[`${key}`],
                    );

                    response.data[`${key}`] = childAttr.data;
                }
            });

            delete response.data.attributes;
        }
    }

    if (response.data.relationships) {
        Object.assign(response, { relationships: response.data.relationships });

        delete response.data.relationships;
    }

    if (resp.data.included)
        Object.assign(response, { included: resp.data.included });

    // normalize included data
    if (response.included && response.included.length) {
        const includedArr = response.included.map((itm) => {
            if (itm.attributes) {
                Object.assign(itm, { ...itm, ...itm.attributes });

                delete itm.attributes;
            }

            return itm;
        });

        Object.assign(response, { included: includedArr });
    }

    if (resp.data && resp.data.meta)
        Object.assign(response, { meta: resp.data.meta });
    if (resp.data && resp.data.links)
        Object.assign(response, { links: resp.data.links });

    /*
    if (response && response.data && response.data.length) {
        response.data = response.data.map((attr) => {
            if (attr) {
                let newAttr = attr;

                if (attr && Object.keys(attr).length) {
                    response.data.map((attr) => {
                        if (attr.data) {
                            let newAttr = normalizeData(attr);
            
                            console.log('newAttr', newAttr);
            
                            response.data[`${attr}`] = newAttr.data ?? null;
                        }
            
                        return response.data;
                    });
                }

                response.data = response.data.map((attr) => {
                    if (attr.data) {
                        let newAttr = normalizeData(attr);
        
                        console.log('newAttr', newAttr);
        
                        response.data[`${attr}`] = newAttr.data ?? null;
                    }
        
                    return response.data;
                });
            }

            return response.data;
        });
    }
    */

    // console.log('response', response);

    return response;
};

export const prepareSelectOptions = (
    records,
    nameField = 'name',
    idField = 'id',
) => {
    let formattedRecords = [];

    if (records) {
        if (typeof records === 'string') {
            try {
                formattedRecords = parseData(records);
            } catch (parseErr) {
                //
            }
        } else {
            formattedRecords = records;
        }

        if (formattedRecords && formattedRecords.data) {
            formattedRecords = normalizeData(formattedRecords);
        }

        if (formattedRecords && formattedRecords.data)
            formattedRecords = formattedRecords.data;

        // if (typeof formattedRecords === 'object') {
        //     let convertedData = [];

        //     formattedRecords = Object.keys(formattedRecords).map((idx) => {
        //         convertedData.push(formattedRecords[idx]);
        //     });

        //     console.log('convertedData', convertedData);
        // }

        if (formattedRecords && formattedRecords.length) {
            let options = [];

            const parentOptions = formattedRecords.filter(
                (itm) => !itm.parent_id,
            );

            if (parentOptions.length) {
                parentOptions.map((parentItm) => {
                    const subOptions = formattedRecords.filter(
                        (itm) =>
                            itm.parent_id && itm.parent_id === parentItm.id,
                    );

                    const optionObj = {};

                    if (subOptions.length) {
                        Object.assign(optionObj, {
                            label: parentItm[`${nameField}`],
                            options: [],
                        });

                        subOptions.map((subOpt) => {
                            let lblStr = '';

                            if (subOpt[`${nameField}`]) {
                                lblStr = `${parentItm[`${nameField}`]} -> ${
                                    subOpt[`${nameField}`]
                                }`;
                            }

                            optionObj.options.push({
                                text: lblStr,
                                value: subOpt[`${idField}`],
                            });

                            return subOpt;
                        });

                        options.push(optionObj);
                    } else {
                        options.push({
                            text: parentItm[`${nameField}`] || '',
                            value: parentItm[`${idField}`],
                        });
                    }

                    return parentItm;
                });
            } else {
                formattedRecords.map((itm) => {
                    options.push({
                        text: itm[`${nameField}`] || '',
                        value: itm[`${idField}`],
                    });

                    return itm;
                });
            }

            formattedRecords = options;
        }
    }

    if (!formattedRecords || !Array.isArray(formattedRecords)) return [];

    return formattedRecords;
};

export const parseData = (str) => {
    let data;

    try {
        data = JSON.parse(str);
    } catch (err) {
        //
    }

    return data;
};

export const parseAndNormalizeData = (str) => {
    let data;

    try {
        data = parseData(str);
        data = normalizeData(data);
    } catch (err) {
        //
    }

    return data;
};

export const convertObjectToArray = (obj) => {
    const data = [];

    try {
        if (typeof obj === 'object') {
            Object.keys(obj).map((idx) => {
                data.push(obj[idx]);
            });
        }
    } catch (err) {
        //
    }

    return data;
};
