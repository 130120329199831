import { getQueryStringParams } from './utils';
/*eslint no-undef: 0*/
$(function () {
    $('#btnPrint').on('click', function () {
        const { origin, pathname } = window.location;

        const qsObj = getQueryStringParams(window.location.search);
        let qs = '';

        // letter filter
        if (qsObj.letter) {
            if (!qs) qs = '?';

            qs += `letter=${qsObj.letter}`;
        }

        // search query
        if (qsObj.q) {
            if (!qs) {
                qs = '?';
            } else {
                qs += '&';
            }

            qs += `q=${qsObj.q}`;
        }

        window.open(`${origin}${pathname}/print${qs}`, 'print-window');
    });

    $('.portal-popup').click(function (e) {
        if (e && e.target && e.target.href) {
            // const popupWindow = window.open(e.target.href, 'portalPopup', 'height=800,width=900,toolbar=0,menubar=0,location=0');
            const popupWindow = window.open(
                e.target.href,
                'portalPopup',
                'height=800,width=900',
            );

            if (window.focus) popupWindow.focus();
        }

        return false;
    });
});
